<template>
  <b-container fluid>
    <iq-card>
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('irriPumpInformation.pump_information_report') }}</h4>
      </template>
      <template v-slot:body>
      <b-row>
        <b-col lg="6" md="6" sm="12" xs="12">
          <b-form-group
            class="row"
            label-cols-sm="3"
            :label="$t('globalTrans.organization')"
            label-for="org_id"
            >
            <b-form-select
              plain
              v-model="search.org_id"
              :options="organizationList"
              id="org_id"
              >
              <template v-slot:first>
                <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
              </template>
            </b-form-select>
            </b-form-group>
        </b-col>
        <b-col lg="6" md="6" sm="12" xs="12">
          <b-form-group
            class="row"
            label-cols-sm="3"
            :label="$t('irriPumpInformation.project')"
            label-for="project_id"
            >
            <b-form-select
              plain
              v-model="search.project_id"
              :options="projectList"
              id="project_id"
              >
              <template v-slot:first>
                <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
              </template>
            </b-form-select>
            </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="6" md="6" sm="12" xs="12">
          <b-form-group
            class="row"
            label-cols-sm="3"
            :label="$t('org_pro_division.division')"
            label-for="division_id"
            >
            <b-form-select
              plain
              v-model="search.division_id"
              :options="divisionList"
              id="division_id"
              >
              <template v-slot:first>
                <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
              </template>
            </b-form-select>
            </b-form-group>
        </b-col>
        <b-col lg="6" md="6" sm="12" xs="12">
          <b-form-group
            class="row"
            label-cols-sm="3"
            :label="$t('org_pro_district.district')"
            label-for="district_id"
            >
            <b-form-select
              plain
              v-model="search.district_id"
              :options="districtList"
              id="district_id"
              >
              <template v-slot:first>
                <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
              </template>
            </b-form-select>
            </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="6" md="6" sm="12" xs="12">
          <b-form-group
            class="row"
            label-cols-sm="3"
            :label="$t('org_pro_upazilla.upazilla')"
            label-for="upazilla_id"
            >
            <b-form-select
              plain
              v-model="search.upazilla_id"
              :options="upazilaList"
              id="upazilla_id"
              >
              <template v-slot:first>
                <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
              </template>
            </b-form-select>
            </b-form-group>
        </b-col>
        <b-col lg="6" md="6" sm="12" xs="12">
          <b-form-group
            class="row"
            label-cols-sm="3"
            :label="$t('org_pro_union.union')"
            label-for="union_id"
            >
            <b-form-select
              plain
              v-model="search.union_id"
              :options="unionList"
              id="union_id"
              >
              <template v-slot:first>
                <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
              </template>
            </b-form-select>
            </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="6" sm="12">
            <b-form-group
                class="row"
                label-cols-sm="3"
                label-for="mouza_id"
                >
                <template v-slot:label>
                {{ $t('irrigation_config.mouza') }}
                </template>
                <b-form-select
                plain
                v-model="search.mouza_id"
                :options="mouzaList"
                id="mouza_id"
                >
                <template v-slot:first>
                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                </template>
                </b-form-select>
            </b-form-group>
        </b-col>
        <!-- <b-col lg="6" md="6" sm="12" xs="12">
          <b-form-group
            class="row"
            label-cols-sm="3"
            :label="$t('irriPumpInformation.mouza_no')"
            label-for="mouza_no"
            >
            <b-form-input
              plain
              v-model="search.mouza_no"
              id="mouza_no"
              >
            </b-form-input>
            </b-form-group>
        </b-col> -->
        <b-col lg="6" md="6" sm="12" xs="12">
          <b-form-group
            class="row"
            label-cols-sm="3"
            :label="$t('irriPumpInformation.jl_no')"
            label-for="jl_no"
            >
            <b-form-input
              plain
              v-model="search.jl_no"
              id="jl_no"
              >
            </b-form-input>
            </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="6" md="6" sm="12" xs="12">
          <b-form-group
            class="row"
            label-cols-sm="3"
            :label="$t('irriPumpInformation.plot_no')"
            label-for="plot_no"
            >
            <b-form-input
              plain
              v-model="search.plot_no"
              id="plot_no"
              >
            </b-form-input>
            </b-form-group>
        </b-col>
        <b-col lg="6" md="6" sm="12" xs="12">
          <b-form-group
            class="row"
            label-cols-sm="3"
            :label="$t('irriPumpInformation.pump_id')"
            label-for="pump_id"
            >
            <b-form-input
              plain
              v-model="search.pump_id"
              id="pump_id"
              >
            </b-form-input>
            </b-form-group>
        </b-col>
        <b-col cols="12" class="text-right">
      <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
        </b-col>
      </b-row>
      </template>
    </iq-card>
    <b-row v-show="showData">
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('irriPumpInformation.pump_information_report') }}</h4>
          </template>
          <template v-slot:headerAction>
            <b-button class="btn_add_new mr-2" @click="pdfExport">
              <i class="fas fa-print"></i>{{  $t('globalTrans.print') }}
            </b-button>
            <export-excel
              class="btn btn_add_new"
              :data="dataCustomize"
              :fields= "json_fields"
              :title="$t('irriPumpInformation.Pump_Information_Report')"
              worksheet="$t('irriPumpInformation.Pump_Information_Report')"
              name="Pump-Information-Report.xls">
              <i class="far fa-file-excel"></i>{{ $t('globalTrans.export_excel') }}
            </export-excel>
            <!-- <export-excel
                class="btn btn-danger"
                :data="dataCustomize"
                worksheet="Pump Information Report Sheet"
                :fields= "json_fields"
                type="csv"
                name="filename.xls">
                {{ $t('globalTrans.export_csv') }}
            </export-excel> -->
          </template>
          <template v-slot:body>
            <b-overlay :show="loadingState">
              <b-row>
                <b-col md="12" class="table-responsive">
                  <b-table bordered hover :items="pumpInformations" :fields="columns" aria-hidden="loading | listReload ? 'true' : null">
                    <template v-slot:cell(index)="data">
                      {{ $n(data.index + pagination.slOffset) }}
                    </template>
                    <template v-slot:cell(pump_id)="data">
                      <!-- <span>{{ $n(data.item.pump_id, { useGrouping: false }) }}</span> -->
                      <span>{{ data.item.pump_id }}</span>
                    </template>
                    <template v-slot:cell(water_group_id)="data">
                      <!-- <span>{{ $n(data.item.water_group_id, { useGrouping: false }) }}</span> -->
                      <span>{{ data.item.water_group_id }}</span>
                    </template>
                    <template v-slot:cell(division_name_bn)="data">
                      <span>{{ data.item.division_name_bn + ', ' + data.item.district_name_bn + ', ' + data.item.upazilla_name_bn + ', ' + data.item.union_name_bn }}</span>
                    </template>
                    <template v-slot:cell(division_name)="data">
                      <span>{{ data.item.division_name + ', ' + data.item.district_name + ', ' + data.item.upazilla_name + ', ' + data.item.union_name }}</span>
                    </template>
                    <template v-slot:cell(latitude)="data">
                      <b-button v-if="data.item.latitude !== ''" variant="primary" v-b-modal.modal-map @click="mapModalOpen(data.item)">
                        Geo Location Link
                      </b-button>
                    </template>
                    <template v-slot:cell(status)="data">
                      {{ data.item.status ? $t('globalTrans.inactive') : $t('globalTrans.active') }}
                    </template>
                    <template v-slot:cell(action)="data">
                      <b-button class="mr-1" title="View Complain" v-b-modal.modal-detail variant=" iq-bg-success" size="sm" @click="details(data.item)"><i class="ri-eye-line m-0 "></i></b-button>
                    </template>
                  </b-table>
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-modal id="modal-detail" size="lg" :title="detailTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
        <DetailModal :id="testId" :key="testId"/>
    </b-modal>
    <b-modal id="modal-map" size="lg" :title="mapTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
        <MapModal :latitude="latitude" :longitude="longitude"/>
    </b-modal>
  </b-container>
</template>
<script>
import { core } from '../../../../../config/pluginInit'
import DetailModal from './DetailModal'
import MapModal from './MapModal'
import { pumpInformationReportList } from '../../api/routes'
import RestApi, { irriSchemeServiceBaseUrl } from '../../../../../config/api_config'
import HierarchycalNames from '@/Utils/common'
import excel from 'vue-excel-export'
import Vue from 'vue'
import { mapGetters } from 'vuex'
import ExportPdf from '@/Utils/export-pdf'
Vue.use(excel)
const excelColumn = {
  'Pump Id': 'pump_id',
  'Water Group Id': 'water_group_id',
  'Pump Location': 'area',
  status: 'status'
}
const excelColumnBn = {
  'পাম্প আইডি': 'pump_id',
  'পানির গ্রুপ আইডি': 'water_group_id',
  'পাম্পের স্থান': 'area',
  অবস্থা: 'status'
}

export default {
  name: 'UiDataTable',
  components: {
    DetailModal, MapModal
  },
  data () {
    return {
      showData: false,
      pagination: {
        currentPage: 1,
        totalRows: 0,
        perPage: this.$store.state.commonObj.perPage,
        slOffset: 1
      },
      search: {
        org_id: 0,
        project_id: '0',
        division_id: '0',
        district_id: '0',
        upazilla_id: '0',
        union_id: '0',
        mouza_id: 0,
        // mouza_no: '',
        jl_no: '',
        plot_no: '',
        pump_no: ''
      },
      testId: 0,
      latitude: 0,
      longitude: 0,
      rows: [],
      pumpInformations: [],
      projectList: [],
      districtList: [],
      upazilaList: [],
      mouzaList: [],
      unionList: []
    }
  },
  computed: {
    ...mapGetters({
      authUser: 'Auth/authUser'
    }),
    json_fields: function () {
      return this.$i18n.locale === 'bn' ? excelColumnBn : excelColumn
    },
    organizationList: function () {
      return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
    },
    formTitle () {
       return (this.testId === 0) ? this.$t('irriPumpInformation.pump_information_entry') : this.$t('irriPumpInformation.pump_information') + ' ' + this.$t('globalTrans.modify')
    },
    detailTitle () {
       return this.$t('irriPumpInformation.pump_information_detail')
    },
    mapTitle () {
       return this.$t('irriPumpInformation.mapTitle')
    },
    loading () {
      return this.$store.state.commonObj.loading
    },
    listReload () {
      return this.$store.state.commonObj.listReload
    },
    loadingState () {
      if (this.listReload) {
        return true
      } else if (this.loading) {
        return true
      } else {
        return false
      }
    },
    currentLocale () {
      return this.$i18n.locale
    },
    dataCustomize () {
      const pumpInformations = this.pumpInformations
      const listContractor = pumpInformations.map(item => {
        return Object.assign({}, item, {
          area: this.$i18n.locale === 'en' ? item.area_en : item.area_bn,
          pump_id: this.$n(item.pump_id, { useGrouping: false }),
          status: item.status ? this.$t('globalTrans.inactive') : this.$t('globalTrans.active')
        })
      })
      return listContractor
    },
    columns () {
      const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
          { label: this.$t('irriPumpInformation.pump_id'), class: 'text-left' },
          { label: this.$t('irriPumpInformation.water_group_id'), class: 'text-left' },
          { label: this.$t('irriPumpInformation.pump_location'), class: 'text-left' },
          { label: this.$t('irriPumpInformation.pump_geo_location'), class: 'text-left' },
          { label: this.$t('globalTrans.status'), class: 'text-left' }
        ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'pump_id' },
          { key: 'water_group_id' },
          { key: 'division_name_bn' },
          { key: 'latitude' },
          { key: 'status' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'pump_id' },
          { key: 'water_group_id' },
          { key: 'division_name' },
          { key: 'latitude' },
          { key: 'status' }
        ]
      }

      return labels.map((item, index) => {
          return Object.assign(item, keys[index])
        })
    },
    divisionList: function () {
      const divisions = this.$store.state.commonObj.divisionList.filter(item => item.status === 0)
      return divisions.map((item) => {
        if (this.$i18n.locale === 'bn') {
          return { value: item.value, text: item.text_bn !== 'undefined' ? item.text_bn : '' }
        } else {
          return { value: item.value, text: item.text_en !== 'undefined' ? item.text_en : '' }
        }
      })
    }
  },
  watch: {
    loadingState: function (newVal, oldVal) {
      if (newVal) {
        this.loadData()
      }
    },
    'search.org_id': function (newVal, oldVal) {
      this.getProjectlist(newVal)
    },
    'search.division_id': function (newVal, oldVal) {
      this.districtList = this.getDistrictList(newVal)
    },
    'search.district_id': function (newVal, oldVal) {
      this.upazilaList = this.getUpazilaList(newVal)
    },
    'search.upazilla_id': function (newVal, oldVal) {
      this.unionList = this.getUnionList(newVal)
    },
    'search.union_id': function (newVal, oldVal) {
      this.mouzaList = this.getMouzaList(newVal)
    },
    currentLocale: function (newVal, oldVal) {
        if (newVal !== oldVal) {
            if (this.search.division_id) {
              this.districtList = this.getDistrictList()
            }
            if (this.search.district_id) {
              this.upazilaList = this.getUpazilaList()
            }
            if (this.search.upazilla_id) {
              this.unionList = this.getUnionList()
            }
            if (this.search.org_id) {
              this.getProjectlist()
            }
        }
    }
  },
  created () {
  },
  mounted () {
    core.index()
    if (this.authUser.role_id === 0 && this.authUser.is_org_admin === 1) {
      this.search = Object.assign({}, this.search, {
        org_id: this.authUser.office_detail.org_id
      })
    } else if (this.authUser.role_id === 0) {
      this.search = Object.assign({}, this.search, {
        org_id: this.authUser.office_detail.org_id,
        division_id: this.authUser.office_detail.division_id,
        district_id: this.authUser.office_detail.district_id,
        upazilla_id: this.authUser.office_detail.upazilla_id
      })
    }
  },
  methods: {
    mapModalOpen (item) {
      this.latitude = item.latitude
      this.longitude = item.longitude
    },
    resetId () {
      this.testId = 0
    },
    testClick () {
      this.loadData()
    },
    default () {
      return {
        id: this.rows.length,
        division_id: '',
        district_id: '',
        upazilla_id: '',
        pumpInformation_name: '',
        pumpInformation_name_bn: ''
      }
    },
    searchData () {
        if (this.authUser.role_id === 0 && this.authUser.is_org_admin === 1) {
          this.search = Object.assign({}, this.search, {
            org_id: this.authUser.office_detail.org_id
          })
        } else if (this.authUser.role_id === 0) {
          this.search = Object.assign({}, this.search, {
            org_id: this.authUser.office_detail.org_id,
            division_id: this.authUser.office_detail.division_id,
            district_id: this.authUser.office_detail.district_id,
            upazilla_id: this.authUser.office_detail.upazilla_id
          })
        }
        this.loadData()
    },
    loadData () {
      RestApi.getData(irriSchemeServiceBaseUrl, pumpInformationReportList, this.search).then(response => {
        if (response.success) {
          this.getRelationalData(response)
        }
      })
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    },
    getRelationalData (data) {
      if (data.data.length !== 0) {
        this.showData = true
      }
      const organizationList = this.$store.state.commonObj.organizationProfileList
      const divisionList = this.$store.state.commonObj.divisionList
      const districtList = this.$store.state.commonObj.districtList
      const upazilaList = this.$store.state.commonObj.upazilaList
      const unionList = this.$store.state.commonObj.unionList
      const pumpInformationData = data.data.map(item => {
        const orgObject = organizationList.find(oganization => oganization.value === item.org_id)
        const divisionObject = divisionList.find(division => division.value === item.division_id)
        const districtObject = districtList.find(district => district.value === item.district_id)
        const upazilaObject = upazilaList.find(upazilla => upazilla.value === item.upazilla_id)
        const unionObject = unionList.find(union => union.value === item.union_id)
        const orgData = {
          org_name: orgObject.text,
          org_name_bn: orgObject.text_bn
        }
        const divisionData = {
          division_name: divisionObject.text,
          division_name_bn: divisionObject.text_bn
        }
        const districtData = {
          district_name: districtObject.text,
          district_name_bn: districtObject.text_bn
        }
        const upazillaData = {
          upazilla_name: upazilaObject.text,
          upazilla_name_bn: upazilaObject.text_bn
        }
        const unionData = {
          union_name: unionObject.text,
          union_name_bn: unionObject.text_bn
        }
        item.area_en = divisionObject.text + ', ' + districtObject.text + ', ' + upazilaObject.text + ', ' + unionObject.text
        item.area_bn = divisionObject.text_bn + ', ' + districtObject.text_bn + ', ' + upazilaObject.text_bn + ', ' + unionObject.text_bn
        return Object.assign({}, item, orgData, divisionData, districtData, upazillaData, unionData)
      })
      this.pumpInformations = pumpInformationData
    },
    getProjectlist (orgId) {
      this.projectList = this.$store.state.IrriConfig.commonObj.projectList.filter(project => project.org_id === orgId && project.status === 0)
    },
    getDistrictList (divisionId = null) {
      const districtList = this.$store.state.commonObj.districtList.filter(item => item.status === 0)
      if (divisionId) {
        return districtList.filter(district => district.division_id === divisionId)
      }
      return districtList
    },
    getUpazilaList (districtId = null) {
      const upazilaList = this.$store.state.commonObj.upazilaList.filter(item => item.status === 0)
      if (districtId) {
        return upazilaList.filter(upazila => upazila.district_id === districtId)
      }
      return upazilaList
    },
    getUnionList (upazillaId = null) {
      const unionList = this.$store.state.commonObj.unionList.filter(item => item.status === 0)
      if (upazillaId) {
        return unionList.filter(union => union.upazilla_id === upazillaId)
      }
      return unionList
    },
    getMouzaList (unionId = null) {
      const mouzaList = this.$store.state.IrriConfig.commonObj.mouzaList.filter(item => item.status === 0)
      if (unionId) {
        return mouzaList.filter(union => union.union_id === parseInt(unionId))
      }
      return mouzaList
    },
    pdfExport () {
      const rowData = this.getPdfData()
      const labels = [
          { text: this.$t('globalTrans.sl_no'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('globalTrans.organization'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('irriPumpInformation.pump_id'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('irriPumpInformation.water_group_id'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('irriPumpInformation.mouza_no'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('irriPumpInformation.jl_no'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('irriPumpInformation.operator_location'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('globalTrans.status'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) }
        ]
      rowData.unshift(labels)
      const reportTitle = this.$i18n.locale === 'en' ? 'Pump Report' : 'পাম্প রিপোর্ট'
      const columnWids = ['8%', '25%', '8%', '10%', '10%', '8%', '8%', '*']
      ExportPdf.exportPdf(irriSchemeServiceBaseUrl, '/report-heading/detail', this.search.org_id, reportTitle, rowData, columnWids)
    },
    getPdfData () {
      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'serial_no' },
          { key: 'org_name_bn' },
          { key: 'pump_id' },
          { key: 'water_group_id' },
          { key: 'mouza_no' },
          { key: 'jl_no' },
          { key: 'operator_location' },
          { key: 'status' }
        ]
      } else {
        keys = [
          { key: 'serial_no' },
          { key: 'org_name' },
          { key: 'pump_id' },
          { key: 'water_group_id' },
          { key: 'mouza_no' },
          { key: 'jl_no' },
          { key: 'operator_location' },
          { key: 'status' }
        ]
      }
      var serial = 0
      const listData = this.pumpInformations.map(item => {
          const allNames = HierarchycalNames.getCommonHierarchyNames(item.union_id, 'union')
          serial += 1
          const rowData = keys.map(keyItem => {
              if (keyItem.key === 'operator_location') {
                return { text: `${allNames.divisionName}, ${allNames.districtName}, ${allNames.upazilaName}, ${allNames.unionName}` }
            }

            if (keyItem.key === 'serial_no') {
                return { text: this.$n(serial) }
            }
            if (keyItem.key === 'status') {
              return { text: item.status ? this.$t('globalTrans.inactive') : this.$t('globalTrans.active') }
            }
            if (keyItem.key === 'pump_id') {
              return { text: this.$n(item.pump_id, { useGrouping: false }) }
            }
            return { text: item[keyItem.key] }
        })

        return rowData
      })
      return listData
    }
  }
}
</script>
